@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
}

span,
img {
  display: block;
}

a {
  font-weight: normal;
}

select {
  display: block;
  margin: 5px 0;
}

.input-cont {
  width: 100% !important;
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}

.filteres {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.filteres::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.filteres .filter {
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  border: 1px solid #11988b;
  color: #11988b;
  cursor: pointer;
}

.filteres .filter:hover {
  background: #fff;
}

.filteres .filter.active {
  background: #11988b;
  color: #fff;
}

.filters .date-picker {
  margin: 0 100px;
  background: red;
}

.model-content {
  position: fixed;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cont {
  background: #f3f3f3;
  height: 100vh;
  width: 100%;
  display: flex;
}

.cont::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.side-bar {
  width: 250px;
  background: #218c82;
  box-shadow: 3px 0 7px rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  position: relative;
}

.routes {
  width: calc(100% - 260px);
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  padding-right: 20px;
}

.side-bar .navs {
  margin-top: 10px;
}

.side-bar .navs a {
  display: flex;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 15px;
  border-bottom: 1px dashed #bdc3c7;
  transition: all 150ms linear;
}

.side-bar .navs a i {
  margin-right: 10px;
}

.side-bar .navs a:hover {
  background: #11988b;
}

.side-bar .navs a.nav-active {
  background: #0f7268;
}

.users table {
  margin-bottom: 30px;
}

table {
  font-size: 15px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

table::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.users .card {
  width: 600px;
  padding: 30px 15px;
  border-radius: 10px;
  max-width: calc(100% - 30px);
}

.users .card h2 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 0;
  width: 100%;
  border-bottom: 2px solid #11988b;
  padding-bottom: 10px;
  color: #11988b;
}

.users .card span {
  margin: 7.5px 0;
  font-size: 15px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #f3f3f3;
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.login main {
  width: 350px;
  height: 250px;
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.login main .input-field {
  margin: 5px 0;
  width: 100%;
}

.login main .input-field input {
  font-size: 15px;
}

.login main .btn {
  margin-top: 20px;
  width: 200px;
}

.pin main input {
  font-size: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  height: 40px !important;
  width: 40px !important;
  margin: 0 5px !important;
}

.recharge-list .card {
  width: 400px;
  max-width: calc(100% - 30px);
  /* height: 200px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 15px;
}

.recharge-list .card h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #11988b;
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.recharge-list .card button {
  width: 100px;
  margin: 0 10px;
}

.recharge-list .card span {
  font-size: 15px;
  margin: 7.5px 0;
}

.side-bar .logout {
  position: absolute;
  left: 20px;
  bottom: 50px;
}

.tab-bar .tabs {
  width: 100%;
  display: flex;
}

.tab-bar .tabs .tab {
  font-size: 15px;
  cursor: pointer;
  background: #fff;
  transition: all 200ms linear;
}

.tab-bar .tabs .tab:hover {
  background: #f2f2f2;
}

.tab-bar .tabs .tab.active {
  color: #fff;
  background: #11988b;
}

.contests .card {
  width: 600px;
  max-width: calc(100% - 30px);
  align-items: flex-start;
  height: auto;
}

.contests .card h2 {
  width: 100%;
  text-align: center;
}

.contests .field {
  width: 100%;
  display: flex;
}

.contests .fields {
  max-height: 250px;
  overflow-y: auto;
}

.contests .fields::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.contests .field {
  display: flex;
  align-items: center;
}

.contests .field input {
  margin-right: 15px;
  width: 100px;
  text-align: center;
}

.contests .field input::placeholder {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.analytics input {
  margin: 0 15px !important;
  width: 150px !important;
}

.analytics .cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.analytics .cards .card {
  min-width: 200px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
}

.analytics .cards .card span {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

.analytics .cards .card h2 {
  font-weight: normal;
  font-size: 30px;
  margin: 0;
  margin-top: 15px;
}

.manage .range-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  font-size: 18px;
}

.sessions-filter {
  display: flex;
  margin-bottom: 20px;
}

.sf {
  width: 100px;
  text-align: center;
  background: #fff;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
}

.sf.active {
  background: #11988b;
  color: #fff;
}
